const FilterPlugin = class {
 
  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */
 
  install (Vue, options) {
    Vue.filter('number', function (value, decimals, user) {
      return fn.numberToString(value, decimals, user || true)
    })
    Vue.filter('currency', function (value, unit, user) {
      return fn.currency(value, unit || 'EUR', user || true)
    })
    Vue.filter('date', function (dateObj) {
      return fn.dateToString(dateObj, true, 'short')
    })
    Vue.filter('date_short', function (dateObj) {
      return fn.dateToString(dateObj, true, 'short')
    })
    Vue.filter('date_weekday', function (dateObj) {
      return fn.dateToString(dateObj, true, 'weekday')
    })
    Vue.filter('date_long', function (dateObj) {
      return fn.dateToString(dateObj, true, 'long')
    }) 
    Vue.filter('time', function (dateObj) {
      return fn.timeToString(dateObj, true, 'short')
    })
    Vue.filter('time_short', function (dateObj) {
      return fn.timeToString(dateObj, true, 'short')
    })
    Vue.filter('time_long', function (dateObj) {
      return fn.timeToString(dateObj, true, 'long')
    })
    Vue.filter('replace', function (string, search, replace) {
      string = fn.toString(string)
      return string.replace(search, replace)
    })
  }
}

export default new FilterPlugin()