require('@/services/Logger')
require('@/services/Functions')
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import VuePlyr from 'vue-plyr'
import Events from '@/plugins/Events'
import API from '@/plugins/API'
import Filter from '@/plugins/Filter'
import DOM from '@/plugins/DOM'
import Image from '@/plugins/Image'
import Scroll from '@/plugins/Scroll'
import Meta from '@/plugins/Meta'
//import Analytics from '@/plugins/Analytics'
import Router from '@/services/Router'
import Store from '@/services/Store'
import Locale from '@/services/Locale'
//import { analytics as analyticsConfig } from '@/config/global.json'
import { breakpoints } from '@/config/style.json'
import imagesConfig from '@/config/images.json'

/*
|--------------------------------------------------------------------------
| Configuration
|--------------------------------------------------------------------------
*/

Vue.use(Vue2TouchEvents)
Vue.use(VuePlyr)
Vue.use(DOM, {
  breakpoints: breakpoints,
  loadGlobalElements: true
})
Vue.use(Events)
Vue.use(API)
Vue.use(Filter)
Vue.use(Image, {
  images: imagesConfig,
  breakpoints: breakpoints
})
Vue.use(Scroll)
Vue.use(Meta)
//Vue.use(Analytics, analyticsConfig)
Vue.config.productionTip = false
Store.commit('setEnviromentVars')

/*
|--------------------------------------------------------------------------
| Mount application
|--------------------------------------------------------------------------
*/

Promise.resolve()
  .then(() => {
    info('loading app from api')
    return Store.dispatch('initLanguages')
  })
  .then(() => {
    return Promise.all([
      Image.loadSprite(process.env.BASE_URL + 'svg/sprite.svg', 'sprite'),
      Store.dispatch('init', Object.keys(Store._modules.root._children))
    ])
  })
  .then(() => {
    new Vue({
      router : Router.component(),
      store: Store,
      i18n: Locale.component(),
      template: '<router-view></router-view>'
    }).$mount('#page')
  })
  .catch((reason) => {
    error(reason.msg, reason.status)
  })
