import API from '@/plugins/API'
import Form from '@/services/Form'

const requestEventStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    status: 0, // 0 = init, 1 = sent, 2 = input error, 10 = unknown error, > 400 http errors
    products: [],
    quantity: 0,
    total: 0,
    showForm: false,
    maxQuantity: 10,
    data: {
      salutation: {
        type: 'select',
        value: null,
        preset: 'female',
        error: false,
        required: true,
        options: [{
          key: 'female'
        },{
          key: 'male'
        }]
      },
      prename: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      surname: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      organisation: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      address: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      zip: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      town: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      country: {
        type: 'text',
        value: null,
        preset: 'Deutschland',
        error: false,
        required: true
      },
      email: {
        type: 'email',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      message: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      conditions: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: true
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {},

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    initForm (state) {
      state.status = 0
      fn.each(state.data, (field) => {
        if (!field.value) {
          field.value = field.preset
        }
        field.error = false
      })
    },
    initBasket (state) {
      state.quantity = 0
      state.total = 0
      fn.each(state.products, product => {
        product.quantity = 0
      })
    },
    calculateBasket (state) {
      state.quantity = 0
      state.total = 0
      fn.each(state.products, product => {
        state.quantity += product.quantity
        state.total += (product.quantity * product.price)
      })
    },
    showForm (state) {
      state.showForm = true
    },
    hideForm (state) {
      state.showForm = false
    },
    formSent (state) {
      state.status = 1
      fn.each(state.data, (field) => {
        field.value = field.preset
        field.error = false
      })
    },
    noInputError (state) {
      state.status = 0
    },
    inputError (state) {
      state.status = 2
    },
    unknownError (state) {
      state.status = 10
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /**
     * request products
     */
    initProducts ({ state, getters, commit }) {
      state.products = []
      return API.children('/products', {
        order: 'asc',
        limit: 99
      })
        .then((response) => {
          fn.each(response.data.children, product => {
            product.content.quantity = 0
            state.products.push(product.content)
          })
        })
        .catch(() => {})
    },

    /**
     */
    validate ({ state, getters, commit }) {
      var error = false
      fn.each(state.data, (field, name) => {
        field.error = !Form.typeCheck(field)
        if (field.error) {
          error = true
        }
      })
      if (error) {
        commit('inputError')
        return Promise.reject({ status: state.status })
      } else {
        commit('noInputError')
        return Promise.resolve()
      }
    },

    /**
     */
    submit ({ state, commit, dispatch }) {
      return Promise.resolve()
        .then(() => {
          return dispatch('validate')
        })
        .then(() => {
          var data = {
            __preset__: 'shop',
            __from__: state.data.email.value,
            __date__: fn.dateToString(new Date()),
            __time__: fn.timeToString(new Date())
          }

          // add user data
          fn.each(state.data, (field, name) => {
            data[name] = Form.postValue(field, true)
          })

          // add order
          commit('calculateBasket')
          data.products = []
          fn.each(state.products, product => {
            if (product.quantity > 0) {
              data.products.push(
                product.quantity + 
                ' x ' + 
                product.title + 
                ' à ' + 
                fn.currency(product.price, 'EUR', true)
              )
            }
          })
          data.total = fn.currency(state.total, 'EUR', true)
          return API.submit('email', data)
        })
        .then(() => {
          commit('formSent')
          return Promise.resolve({ status: state.status })
        })
        .catch((reason) => {
          return Promise.reject(reason)
        })
    },

    /**
     * required init
     */
    init: ({ dispatch }) => {
      log('init store shop')
      return dispatch('initProducts')
    }
  }
}

export default requestEventStore