import DOM from '@/plugins/DOM'
import Events from '@/plugins/Events'

const ScrollPlugin = class {
  constructor () {
    this.context = window
    this.offset = 0
    this.scrolling = false
    this.eventDelay = 100
  }
 
  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  /**
   */
  install (Vue, options) {
    Vue.prototype.$scroll = {
      to: (target, options) => {
        return this.to(target, options)
      },
      jump: (target, options) => {
        return this.jump(target, options)
      },
      register: (name, target, options) => {
        return this.stack(name, target, options)
      },
      do: (name) => {
        return this.do(name)
      },
      registerContext: (mixed) => {
        return this.registerContext(mixed)
      },
      registerOffset: (mixed) => {
        return this.registerOffset(mixed)
      },
      ready: () => {
        return this.ready()
      }
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */
  
  /**
   * Scrolling to an element or position
   * 
   * target can be:
   * 
   *  - the id of the target element
   *  - the element itself, either from document.getElementById() or an object from Vue.$refs
   *  - a number = the scroll to position
   *  - empty for scroll to top
   * 
   * Events fired:
   * 
   *  - scroll/beforeScroll
   *  - scroll/scrolled
   * 
   * @param {mixed} target
   * @param {object} options, { duration, payload, context, offset}
   */
  to (target, options) {
    return new Promise((resolve, reject) => {
      var el, start, end, clock, requestAnimationFrame, step

      // set options
      options = fn.assign({
        duration: 500,
        payload: null, // payload for events
        context: null,
        offset: 0
      }, options)

      // context can be element, Vue.$ref or id (string)
      options.context = DOM.getElement(options.context) || this.context || window

      // start position
      start = options.context.scrollTop || window.pageYOffset

      if (!target) {
        end = 0
      }
      else if (fn.isInteger(target)) {
        end = target
      }
      else {
        if (fn.isString(target)) {
          target = fn.ltrim(target, '#')
        }
        el = DOM.getElement(target)
        if (!el) {
          reject()
          return
        }
        if (el.nodeName === 'HTML') {
          end = -start
        } else {
          end = this._getOffsetTop(el)
        }
      }

      // add offset of registered scroll offset element
      end -= this._getOffsetHeight()
      
      // add offset to end position
      if (fn.isInteger(options.offset)) {
        end += options.offset
      }

      // before scrolling
      if (this.scrolling) {
        reject()
        return
      }
      Events.toggleScrollEvents(false)
      this.scrolling = true
      Events.trigger('scroll/beforeScroll', options.payload)

      // scroll
      clock = Date.now()
      requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame
      if (!requestAnimationFrame) {
        requestAnimationFrame = (fn) => {
          window.setTimeout(fn, 15)
        }
      }

      step = () => {
        var elapsed
        elapsed = Date.now() - clock
        if (options.context !== window) {
          options.context.scrollTop = this._scrollPosition(start, end, elapsed, options.duration)
        } else {
          window.scroll(0, this._scrollPosition(start, end, elapsed, options.duration))
        }
        if (elapsed > options.duration) {
          window.setTimeout(() => {
            var params = DOM.getScrollPos(options.context, start)
            params.payload = options.payload
            resolve()
            this.scrolling = false
            Events.trigger('scroll/scrolled', params)
            Events.toggleScrollEvents(true)
          }, this.eventDelay)
        } else {
          requestAnimationFrame(step)
        }
      }
      step()
    })
  }

  /**
   * jump to position
   * @param {*} target 
   * @param {*} options 
   */
  jump (target, options) {
    options = fn.assign({}, options)
    options.duration = 0
    return this.to(target, options)
  }

  /**
   * Register a delayed scroll
   * @param {String} name, event name, by which the scroll can be requested
   * @param {mixed} target, option for scroll()
   * @param {Object} options, option for scroll()
   */
  register (name, target, options) {
    return Events.once(name, () => {
      return this.to(target, options)
    })
  }

  /**
   * Request a delayed scroll
   * @param {String} name, event name
   */
  do (name) {
    Events.trigger(name)
  }

  /**
   * scroll inside this container, can always be overwritten by scroll options.context
   * @param {mixed} mixed, string (element-id), html element or vue component
   */
  registerContext (mixed) {
    var context =  DOM.getElement(mixed)
    if (context instanceof HTMLElement) {
      this.context = context
    } else {
      this.context = window
    }
  }

  /**
   * register/unregister an element that functions as offset for scroll
   */
  registerOffset (mixed) {
    var el =  DOM.getElement(mixed)
    if (el instanceof HTMLElement) {
      this.offset = el
    } else {
      this.offset = null
    }
  }

  /**
   * check if scrolling is ready
   * @return {Boolean}, true when not scrolling
   */
  ready () {
    return !this.scrolling
  }
  
  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  /**
   */
  _getOffsetHeight () {
    return this.offset ? DOM.getHeight(this.offset) : 0
  }

  /**
   * calulate offsetTop from (grand-)grand-children to context
   */
  _getOffsetTop (el) {
    var res = el.offsetTop
    if (el.offsetParent && el.offsetParent !== this.context) {
      res += this._getOffsetTop(el.offsetParent)
    }
    return res
  }

  /**
   */
  _scrollPosition (start, end, elapsed, duration) {
    if (elapsed > duration) {
      return end
    }
    return start + (end - start) * this._scrollEaseInOutCubic(elapsed / duration)
  }

  /**
   */
  _scrollEaseInOutCubic (t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  }
}

export default new ScrollPlugin()