import Image from '@/plugins/Image'

const MetaPlugin = class {
 
  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  /**
   */
  install (Vue) {
    this.Vue = Vue
    Vue.prototype.$meta = {
      setPage: (site, page, lang) => {
        return this.setPage(site, page, lang)
      },
      setError: (errno) => {
        return this.setError(errno)
      }
    }
  }
  
  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   */
  setPage (site, page, lang) {
    if (!fn.has(site, 'meta')) {
      throw({ status: 1001, msg: 'site-data for default language not set'})
    }
    if (!fn.has(page, 'meta') || !fn.has(page, 'head')) {
      throw({ status: 1002, msg: 'page-data for default language not set'})
    }

    var meta = []

    var title = this._getTitle(
      site.meta.title,
      page.meta.title,
      page.head.title,
      site.meta.titleorder
    )
    meta.push({
      'tag': 'title',
      'content': title
    })
    meta.push({
      'property': 'og:title',
      'content': title
    })

    var description = this._getContent(
      fn.htmlToText(site.meta.description, true),
      fn.htmlToText(page.meta.description, true)
    )
    meta.push({
      'name': 'description',
      'content': description
    })
    meta.push({
      'property': 'og:description',
      'content': description
    })
    meta.push({
      'name': 'twitter:card',
      'content': description
    })


    meta.push({
      'name': 'keywords',
      'content': this._getContent(
        site.meta.keywords.join(', '),
        page.meta.keywords.join(', ')
      )
    })
    meta.push({
      'name': 'author',
      'content': this._getContent(
        site.meta.author
      )
    })
    meta.push({
      'property': 'og:url',
      'content': fn.currentUrl()
    })
    meta.push({
      'property': 'og:type',
      'content': this._getContent(page.meta.type)
    })
    meta.push({
      'property': 'og:locale',
      'content': this._getContent(fn.upper(lang) + '_' + fn.lower(lang))
    })

    var image = this._getImage(site, page)
    meta.push({
      'property': 'og:image',
      'content': fn.has(image, 'src') ? image.src : null
    })
    meta.push({
      'property': 'og:image:width',
      'content': fn.has(image, 'url') ? fn.toString(image.width) : null
    })
    meta.push({
      'property': 'og:image:height',
      'content': fn.has(image, 'url') ? fn.toString(image.height) : null
    })
    this._domSetMeta(meta)
  }

  /**
   */
  setError (errno) {
    var meta = []
    meta.push({
      'tag': 'title',
      'content': 'Error ' + errno
    })
    this._domSetMeta(meta)
  }
  
  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  /**
   */
  _getTitle (site, page, title, order) {
    return fn.escape(
      order.replace('_', ' | ')
      .replace('site', site)
      .replace('page', fn.isString(page) ? page : title)
    )
  }

  /**
   */
  _getContent (val1, val2) {
    if (fn.isString(val2)) {
      return fn.escape(val2, true)
    }
    if (fn.isString(val1)) {
      return fn.escape(val1, true)
    }
    return null
  }

  /**
   */
  _getImage (site, page) {
    var config = []
    if (
      fn.has(site.api, 'seoImage') &&
      fn.has(site.api.metaImage, 'width') &&
      fn.has(site.api.metaImage, 'height') && 
      fn.has(site.api.metaImage, 'crop')) {
        config = [
          fn.toInteger(site.api.metaImage.width),
          fn.toInteger(site.api.metaImage.height),
          site.api.metaImage.crop
        ]
    } else {
      config = [1200, 630, true]
    }
    if (fn.isObject(page.meta.image)) {
      return Image.get(page.meta.image, config, false, true)
    }
    return Image.get(site.meta.image, config, false, true)
  }

  /**
   */
  _domSetMeta (meta) {
    fn.each(meta, (obj) => {
      if (fn.has(obj, 'tag')) {
        let node = document.head.querySelectorAll(obj.tag)
        if (node.length === 1 && node[0].innerHTML !== obj.content) {
          node[0].innerHTML = obj.content
        }
      } else {
        let node = []
        if (fn.has(obj, 'name')) {
          node = document.head.querySelectorAll('meta[name="' + obj.name + '"]')
        } else if (fn.has(obj, 'property')) {
          node = document.head.querySelectorAll('meta[property="' + obj.property + '"]')
        }
        if (node.length === 1 && node[0].getAttribute('content') !== obj.content) {
          node[0].setAttribute('content', obj.content)
        }
      }
    })
  }
}

export default new MetaPlugin()