// log can be switched off in .env
window.log = (mixed) => {
  if (process.env.VUE_APP_LOGGER) {
    // eslint-disable-next-line
    console.log(mixed)
  }
}

// info and error are always print to console
window.info = (mixed) => {
  // eslint-disable-next-line
  console.log(mixed)
}
window.error = (errmsg, errno) => {
  // eslint-disable-next-line
  console.error(errmsg + (errno ? ' (' + errno + ')' : ''))
}