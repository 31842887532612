const cacheStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    cache: new Map()
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {
    get: (state) => (url) => {
      let id = fn.urlId(url)
      if (state.cache.has(id)) {
        info('get data for ' + url + ' from cache')
        return state.cache.get(id)
      } else {
        return null
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    set (state, params) {
      info('cache data for ' + params.url)
      let id = fn.urlId(params.url)
      state.cache.set(id, params.data)
      if (state.cache.size > fn.toInteger(process.env.VUE_APP_CACHESIZE)) {
        let first =  state.cache.keys().next().value
        state.cache.delete(first)
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /**
     * required init
     */
    init: () => {
      log('init store cache')
      return Promise.resolve()
    }
  }
}

export default cacheStore