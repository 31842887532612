import Events from '@/plugins/Events'
import Scroll from '@/plugins/Scroll'

const DOMPlugin = class {
 
  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  /**
   */
  install (Vue, options) {
    this.Vue = Vue
    this.options = options
    Vue.prototype.$dom = {
      getActiveClasses: (route, uri, activeClass, exactClass) => {
        return this.getActiveClasses(route, uri, activeClass, exactClass)
      },
      getElement: (mixed) => {
        return this.getElement(mixed)
      },
      isElement: (node) => {
        return this.isElement(node)
      },
      getWindowHeight: () => {
        return this.getWindowHeight()
      },
      getWindowWidth: () => {
        return this.getWindowWidth()
      },
      getDocumentHeight: () => {
        return this.getDocumentHeight()
      },
      getDocumentWidth: () => {
        return this.getDocumentWidth()
      },
      getDimensions: (mixed) => {
        return this.getDimensions(mixed)
      },
      getWidth: (mixed) => {
        return this.getWidth(mixed)
      },
      getHeight: (mixed) => {
        return this.getHeight(mixed)
      },
      getScrollPos: (mixed) => {
        return this.getScrollPos(mixed)
      },
      isScrolledIntoView: (mixed, context) => {
        return this.isScrolledIntoView(mixed, context)
      },
      setStyle: (mixed, style, value) => {
        return this.setStyle(mixed, style, value)
      },
      getStyle: (mixed, style) => {
        return this.getStyle(mixed, style)
      },
      getBreakpoint: () => {
        return this.getBreakpoint()
      },
      fixBody: () => {
        return this.fixBody()
      },
      releaseBody: () => {
        return this.releaseBody()
      }
    }
    if (options.loadGlobalElements) {
      var BaseElements = require.context('@/components', true, /^\.\/(base)\/.*\.(vue|js)$/)
      BaseElements.keys().forEach((path) => {
        var Component = BaseElements(path)
        Component = (Component.default || Component)
        Vue.component(fn.pascalCase(Component.name), Component)
      })
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */
  /**
   * @param {mixed} route1, either $route, { path: foo, hash: bar} or string
   * @param {mixed} route2, either $route, { path: foo, hash: bar} or string
   * @param {string} activeClass, the active class to return
   * @param {string} exactClass, the exact active class to return
   * @return string
   */
  getActiveClasses (route1, route2, activeClass, exactClass) {
    route1 = fn.getRoutePath(route1)
    route2 = fn.getRoutePath(route2)
    var res = []
    if (route1 === route2) {
      res.push(activeClass || 'is-active')
      res.push(exactClass || 'is-exact-active')
    } else if (route1.substr(0, route2.length + 1) === fn.rtrim(route2, '/') + '/') {
      res.push(activeClass || 'is-active')
    }
    return res.concat(' ')
  }

  /**
   * mixed can be a string = element-ID or a Vue.$refs
   * If ref="foo" is used on root element or on a component, the reference
   * will point to the vue component. Otherwise it will point to the DOM
   * element. Here we translate to DOM element.
   * @param {Mixed} mixed, string, Vue instance, DOM element
   */
  getElement (mixed) {
    if (mixed instanceof HTMLElement || mixed === window) {
      return mixed
    } else if (fn.isString(mixed)) {
      return document.getElementById(mixed)
    } else if (mixed instanceof this.Vue) {
      return mixed.$el
    }
    return null
  }

  /**
   * @param {HTMLElement} node
   */
  isElement (node) {
    return node && node instanceof HTMLElement
  }

  /**
   */
  getWindowHeight () {
    return window.innerHeight
  }

  /**
   */
  getWindowWidth () {
    return window.innerWidth
  }

  /**
   * inclucing invisible part
   */
  getDocumentHeight () {
    return Math.max(
      document.documentElement['clientHeight'],
      document.body['scrollHeight'],
      document.documentElement['scrollHeight'],
      document.body['offsetHeight'],
      document.documentElement['offsetHeight']
    )
  }

  /**
   * inclucing invisible part
   */
  getDocumentWidth () {
    return Math.max(
      document.documentElement['clientWidth'],
      document.body['scrollWidth'],
      document.documentElement['scrollWidth'],
      document.body['offsetWidth'],
      document.documentElement['offsetWidth']
    )
  }

  /**
   * get dimensions of a dom element
   * @param {mixed} mixed, @see getElement()
   */
  getDimensions (mixed) {
    var node = this.getElement(mixed)
    var res = {}
    if (this.isElement(node)) {
      res.width   = this.getWidth(node)
      res.height  = this.getHeight(node)
      res.top     = node.offsetTop
      res.left    = node.offsetLeft
      res.bottom  = res.top + res.height
      res.right   = res.left + res.width
    }
    return res
  }

  /**
   * gets visible width of element or window
   * @param {mixed} mixed, @see getElement()
   * @return {Number} 
   */
  getWidth (mixed) {
    var node = this.getElement(mixed)
    if (this.isElement(node)) {
      return fn.toInteger(getComputedStyle(node).width.split('px')[0]) // node.offsetWidth
    } else {
      return window.innerWidth
    }
  }

  /**
   * gets visible height of element or window
   * @param {mixed} mixed, @see getElement()
   * @return {Number} 
   */
  getHeight (mixed) {
    var node = this.getElement(mixed)
    if (this.isElement(node)) {
      return fn.toInteger(getComputedStyle(node).height.split('px')[0]) // node.offsetHeight
    } else {
      return window.innerHeight
    }
  }

  /**
   * gets total height of element or window, including not visible parts
   * @param {mixed} mixed, @see getElement()
   * @return {Number} 
   */
  // getContentWidth (mixed) {
  //   var node = this.getElement(mixed)
  //   if (this.isElement(node)) {
  //     return node.scrollWidth
  //   } else {
  //     return document.body.clientWidth
  //   }
  // }

  /**
   * gets total height of element or window, including not visible parts
   * @param {mixed} mixed, @see getElement()
   * @return {Number} 
   */
  // getContentHeight (mixed) {
  //   var node = this.getElement(mixed)
  //   if (this.isElement(node)) {
  //     return node.scrollHeight
  //   } else {
  //     return document.body.clientHeight
  //   }
  // }

  /**
   */
  getScrollPos (mixed, oldTop) {
    var node = this.getElement(mixed)
    var top = this.isElement(node) ? node.scrollTop :  window.scrollY
    var height = this.getHeight(node)
    var res = {
      top: top,
      bottom: top + height,
      scrolled: top > 0
    }
    if (fn.isInteger(oldTop)) {
      res.diff = res.top - oldTop
      if (res.top > oldTop) {
        res.direction = 'down'
      } else if (res.top < oldTop) {
        res.direction = 'up'
      }
    }
    return res
  }

  /**
   * @param {mixed} el, element
   * @param {mixed} context, optional, window on default
   * @return {Boolean}
   */
  isScrolledIntoView (el, context) {
    var node = this.getElement(el)
    var res = {
      visible: false,
      view: false,
      top: false,
      bottom: false
    }
    if (this.isElement(node)) {
      var top = fn.toInteger(node.getBoundingClientRect().top)
      var bottom = fn.toInteger(node.getBoundingClientRect().bottom)
      var height = this.getHeight(context)

      // element begins to become visible on bottom of the context
      // or bottom begins to become visible on top of the context (= something is visible)
      res.visible = (top < height) && (bottom > 0)

      // the COMPLETE element is visible in context (top and bottom) or,
      // if element is bigger than context, when it fills the complete viewport
      res.view = ((top >= 0) && (bottom <= height)) || ((top < 0) && (bottom > height))

      // top of element is scrolled to top of the context and
      // bottom is not scrolled higher than top of context
      res.top = (top <= 0) && (bottom > 0)

      // bottom of element is scrolled to bottom of the context and
      // top is not scrolled lower than bottom of context
      res.bottom = (bottom <= height) && (top < height)
    }
    return res
  }

 
  /**
   * @param {mixed} mixed, @see getElement()
   * @param {mixed} style, empty, property or object
   * @param {String} value, only if style is property
   */
  setStyle (mixed, style, value) {
    var node = this.getElement(mixed)
    if (node) {
      if (!style) {
        node.style = ''
      } else if (fn.isString(style)) {
        node.style[style] = value
      } else if (fn.isObject(style)) {
        fn.each(style, (value, prop) => {
          node.style[prop] = value
        })
      } 
    }
  }

  /**
   * @param {mixed} mixed, @see getElement()
   * @param {String} style, optional: get specific style prop
   */
  getStyle (mixed, style) {
    var node = this.getElement(mixed)
    if (node) {
      if (fn.isString(style)) {
        return node.style[style]
      } else {
        return node.style
      }
    }
  }

  /**
   */
  getBreakpoint () {
    var res
    fn.each(this.options.breakpoints, (value, key) => {
      if (window.innerWidth <= value) {
        res = key
      }
    })
    return res
  }

  /**
   */
  fixBody () {
    Events.toggleScrollEvents(false)
    var scrollPos = this.getScrollPos(Scroll.context)
    if (Scroll.context === window) {
      this.setStyle(document.body, {
        position: 'fixed',
        width: '100%',
        top: '-' + scrollPos.top + 'px'
      })
    } else {
      this.setStyle(Scroll.context, {
        top: '-' + scrollPos.top + 'px'
      })
    }
  }

  /**
   */
  releaseBody () {
    // scrollPos of a locked document is 0, so get value from style property
    var elem = Scroll.context === window ? document.body : Scroll.context
    var top = Math.abs(fn.toInteger(this.getStyle(elem, 'top')))
    this.setStyle(elem, '')
    Scroll.jump(top, {
      context: Scroll.context
    })
    .catch(() => {})
    .finally(() => {
      Events.toggleScrollEvents(true)
    })
  }
}

export default new DOMPlugin()