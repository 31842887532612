const functionsService = class {
  constructor () {
    this.TO_LOWER_CASE = 'TO_LOWER_CASE'
    this.TO_UPPER_CASE = 'TO_UPPER_CASE'
    this.MS_PER_DAY = 1000 * 60 * 60 * 24
    this.format = {
      user: {},
      default: {}
    }
    this.timeStart = null
    this.timeIntermediate = null
    this.timeStop = null
  }

  setLocalization (user, def) {
    this.format.user = user
    this.format.default = def
  }

  isUndefined (val) {
    return typeof val === 'undefined'
  }

  isEmpty (val) {
    return val === '' || val === null || this.isUndefined(val)
  }

  /*
  |--------------------------------------------------------------------------
  | Strings
  |--------------------------------------------------------------------------
  */

  isString (val, min, max) {
    if (typeof val && typeof val === 'string') {
      if (!this.isInteger(min)) {
        min = 1
      }
      if (!this.isInteger(max)) {
        max = val.length
      }
      return val.length >= min && val.length <= max
    }
    return false
  }

  toString (val) {
    if (val === null || this.isArray(val) || this.isObject(val) || val === undefined) {
      return ''
    }
    return val + ''
  }

  // fooBar
  camelCase (...args) {
    let str = args.join(' ')
    let words = this.words(str, this.TO_LOWER_CASE)
    if (words.length > 0) {
      return words[0] + words.slice(1).map((val) => {
        // don't use this.upperFirst
        val = val + ''
        return val.charAt(0).toUpperCase() + val.slice(1)
      }).join('')
    }
    return ''
  }

  // FooBar
  pascalCase (...args) {
    let str = args.join(' ')
    let words = this.words(str, this.TO_LOWER_CASE)
    if (words.length > 0) {
      return words.map((val) => {
        // don't use this.upperFirst
        val = val + ''
        return val.charAt(0).toUpperCase() + val.slice(1)
      }).join('')
    }
    return ''
  }

  // foo-bar
  kebabCase (...args) {
    let str = args.join(' ')
    let words = this.words(str, this.TO_LOWER_CASE)
    return words.join('-')
  }

  // foo_bar
  snakeCase (...args) {
    let str = args.join(' ')
    let words = this.words(str, this.TO_LOWER_CASE)
    return words.join('_')
  }

  upper (val) {
    val = this.toString(val)
    return val.toUpperCase()
  }

  lower (val) {
    val = this.toString(val)
    return val.toLowerCase()
  }

  trim(val, chr) {
    val = this.toString(val)
    var rgxtrim = (!chr) ? new RegExp('^\\s+|\\s+$', 'g') : new RegExp('^'+chr+'+|'+chr+'+$', 'g');
    return val.replace(rgxtrim, '');
  }

  rtrim(val, chr) {
    val = this.toString(val)
    var rgxtrim = (!chr) ? new RegExp('\\s+$') : new RegExp(chr+'+$');
    return val.replace(rgxtrim, '');
  }

  ltrim(val, chr) {
    val = this.toString(val)
    var rgxtrim = (!chr) ? new RegExp('^\\s+') : new RegExp('^'+chr+'+');
    return val.replace(rgxtrim, '');
  }

  upperFirst (val) {
    val = this.toString(val)
    return val.charAt(0).toUpperCase() + val.slice(1)
  }

  escape (unsafe, removeNewlines) {
    if (!unsafe) {
      return
    }
    if (removeNewlines) {
      unsafe = this.removeNewlines(unsafe)
    }
    let map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    }
    return unsafe.replace(/[&<>"']/g, (m) => { return map[m] })
  }

  removeNewlines (str) {
    return str.replace(/\s+/gm, ' ').trim()
  }

  /**
   * opposite of php's addslashes
   * @param {string} str, the string with backslashes
   */
  stripslashes (val) {
    val = val + ''
    return val.replace(/\\(.)/mg, '$1')
  }

  words (str, convert) {
    str = this.toString(str)
    str = str.replace(/([A-Z])/g, ' $1')
    if (convert === this.TO_UPPER_CASE) {
      str = str.toUpperCase()
    }
    if (convert === this.TO_LOWER_CASE) {
      str = str.toLowerCase()
    }
    return str.match(/\b(\w+)\b/g)
  }

  truncate (str, length) {
    if (!this.isString(str)) {
      return ''
    }
    if (length > 3 && str.length > length) {
      str = str.slice(0, length - 3) + '...'
    }
    return str
  }

  /*
  |--------------------------------------------------------------------------
  | Numbers
  |--------------------------------------------------------------------------
  */

  isInteger (val, min, max) {
    return this.isNumber(val, min, max) && Math.floor(val) === val
  }

  isFloat (val, min, max) {
    return this.isNumber(val, min, max) && Math.floor(val) !== val
  }

  // min and max optional
  isNumber (val, min, max) {
    let type = typeof val
    if (type && type === 'number' && isFinite(val)) {
      if (!this.isNumber(min)) {
        min = val
      }
      if (!this.isNumber(max)) {
        max = val
      }
      return (val >= min && val <= max)
    }
    return false
  }

  toInteger (val) {
    return parseInt(val)
  }

  toFloat (val) {
    return parseFloat(val)
  }

  /**
   *
   * @param {number} val, the value to round
   * @param {integer} dec, decimal points
   * @param {string} mode, [ up | down | none ]
   */
  round (val, decimals, mode) {
    let a
    if (decimals === undefined || decimals < 0) {
      a = 1 // zero decimal points
    } else {
      a = Math.pow(10, decimals)
    }
    if (mode === 'up') {
      return Math.ceil(val * a) / a
    } else if (mode === 'down') {
      return Math.floor(val * a) / a
    } else {
      return Math.round(val * a) / a
    }
  }

  /**
   * formats a number, optional in user format
   * @param {Number} val
   * @param {Integer} decimals, optional, null for decimals like in given val
   * @param {Boolean} user, in user format?
   * @return {String}
   */
  numberToString (val, decimals, user) {
    if (!this.isNumber(val)) {
      return val
    }
    var res = this.toFloat(val)
    if (this.isInteger(decimals, 0)) {
      res = res.toFixed(decimals)
    }
    return fn.toString(res)
      .replace('.', this.decPoint(user))
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + this.thousandsSep(user))
  }

  /**
   * unformats a number, optional from user format
   * @param {String} val, the number like .12 | 1.23 | 12 | 1,234.56
   * @param {Boolean} user, is the number formatted with user decimal point?
   */
  stringToNumber (val, user) {
    var reg = new RegExp('[^0-9' + this.regEsc(this.decPoint(user)) + ']')
    val = this.toString(val).replace(reg, '').replace(this.decPoint(user), '.')
    if (val.indexOf('.') === -1) {
      return this.toInteger(val)
    } else {
      return this.toFloat(val)
    }
  }

  /**
   * Create a test-regex for numbers
   * @param {Integet} decimals [ 0 for integer, >0 for float with fixed, * for infinite ]
   * @param {Boolean} user, in user format?
   * @return {RegExp}
   */
  numberRegExp (decimals, user) {
    var res = '^(\\d)*' // thousandSep-test: '^\\d{1,3}([,]?\\d{3})*'
    if (this.isInteger(decimals, 1)) {
      res += '(' + this.regEsc(this.decPoint(user)) + '\\d{0,' + decimals + '})?'
    } else if (decimals === '*') {
      res += '(' + this.regEsc(this.decPoint(user)) + '\\d*)?'
    }
    return new RegExp(res +'$')
  }

  /**
   * @param {Number} val
   * @param {String} currency
   */
  currency (val, currency, user) {
    return this.numberToString(val, 2, user) + ' ' + (currency || 'EUR')
  }

  /**
   * get decimal point
   * @param {Boolean} user, in user format?
   */
  decPoint (user) {
    return user ? this.format.user.number.decPoint : this.format.default.number.decPoint
  }

  /**
   * get thousands separator
   * @param {Boolean} user, in user format?
   */
  thousandsSep (user) {
    return user ? this.format.user.number.thousandsSep : this.format.default.number.thousandsSep
  }

  /*
  |--------------------------------------------------------------------------
  | Arrays
  |--------------------------------------------------------------------------
  */

  isArray (val) {
    return Array.isArray(val)
  }

  /**
   * @param {mixed} needle
   * @param {collection} haystack
   */
  inArray (val, arr) {
    if (this.isArray(arr)) {
      return this.indexOf(val, arr) > -1
    }
    return false
  }

  indexOf (val, arr) {
    var index = -1
    while (++index < arr.length) {
      if (arr[index] === val) {
        return index
      }
    }
    return -1
  }

  unique (arr) {
    if (!this.isArray(arr)) {
      return arr
    }
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index
    });
  }

  /*
  |--------------------------------------------------------------------------
  | Objects
  |--------------------------------------------------------------------------
  */

  isObject (val) {
    let type = typeof val
    return val &&
      type &&
      type === 'object' &&
      !this.isArray(val) &&
      !this.isMap(val)
  }

  /**
   * will return the node from an object by a given reference as
   * dot-separated path e.g. foo.bar.nodename
   * function get() from https://github.com/sindresorhus/dot-prop
   * (set() and delete() also available in this project)
   * @param {object} obj
   * @param {string} path
   * @param {bool} returnParent, return the last valid parent path, if path is not found
   */
  path (obj, path, returnParent) {
    if (!this.isObject(obj) || !this.isString(path)) {
      return null
    }
    const pathArray = path.split('.')
    for (let i = 0; i < pathArray.length; i++) {
      if (!Object.prototype.propertyIsEnumerable.call(obj, pathArray[i])) {
        return returnParent ? obj : null
      }
      let child = obj[pathArray[i]]
      if (child === undefined || child === null) {
        if (i !== pathArray.length - 1) {
          return returnParent ? obj : null
        }
        break
      }
      obj = child
    }
    return obj
  }

  assign = Object.assign || this._assign

  /** Polyfill for Android webview */
  _assign (target) {
    var sources = [], len = arguments.length - 1
    while (len-- > 0) {
      sources[ len ] = arguments[ len + 1 ]
    }
    sources.forEach((source) => {
      Object.keys(source || {}).forEach(
        (key) => {
          return target[key] = source[key]
        }
      )
    })
    return target
  }

  /*
  |--------------------------------------------------------------------------
  | Maps
  |--------------------------------------------------------------------------
  */

  isMap (val) {
    return val instanceof Map
  }

  /*
  |--------------------------------------------------------------------------
  | Interable
  |--------------------------------------------------------------------------
  */

  isIterable (val) {
    return this.isObject(val) || this.isArray(val) || this.isMap(val)
  }

  each (obj, iteratee) {
    if (this.isObject(obj)) {
      Object.keys(obj || {}).forEach((key) => {
        return iteratee(obj[key], key, obj)
      })
    } else if (this.isArray(obj) || this.isMap(obj)) {
      obj.forEach((value, key) => {
        return iteratee(value, key, obj)
      })
    }
  }

  /**
   * optional compare value, if key exists
   */
  has (obj, key, value) {
    if (this.isArray(obj) && obj[key] !== undefined) {
      return value !== undefined ? obj[key] === value : true
    } else if (this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key)) {
      return value !== undefined ? obj[key] === value : true
    } else if (this.isMap(obj) && obj.has(key)) {
      return value !== undefined ? obj.get(key) === value : true
    }
    return false
  }

  get (obj, key) {
    if (this.isArray(obj) && obj[key] !== undefined) {
      return obj[key]
    } else if (this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key)) {
      return obj[key]
    } else if (this.isMap(obj) && obj.has(key)) {
      return obj.get(key)
    }
    return null
  }

  set (obj, key, value) {
    if (this.isArray(obj)) {
      obj.push(value)
    } else if (this.isObject(obj)) {
      obj[key] = value
    } else if (this.isMap(obj)) {
      obj.set(key, value)
    }
  }

  // be aware: no deep cloning here!
  clone (obj) {
    if (this.isArray(obj)) {
      return obj.slice(0)
    } else if (this.isObject(obj)) {
      return this.assign({}, obj)
    } else if (this.isMap(obj)) {
      return new Map(obj)
    }
    return null
  }

  delete (obj, key) {
    if (this.isArray(obj) && obj[key] !== undefined) {
      obj.splice(key, 1);
    } else if (this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key)) {
      delete obj[key]
    } else if (this.isMap(obj) && obj.has(key)) {
      obj.delete(key)
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Functions
  |--------------------------------------------------------------------------
  */

  isFunction (val) {
    return val && {}.toString.call(val) === '[object Function]'
  }

  /*
  |--------------------------------------------------------------------------
  | Boolean
  |--------------------------------------------------------------------------
  */

  isBool (val) {
    return (val === true || val === false)
  }

  isTrue (val) {
    return val === true
  }

  isFalse (val) {
    return val === false
  }

  toBool (val) {
    if (this.isBool(val)) {
      return val
    }
    if (/^\d+$/.test(val)) {
      val = this.toFloat(val)
    }
    if (this.isNumber(val)) {
      return val > 0
    } else if (this.isString(val) && this.upper(val) === 'TRUE') {
      return true
    } 
    return false
  }

  boolToNumber (val) {
    if (val === false) {
      return 0
    }
    if (val === true) {
      return 1
    }
    return val
  }

  /*
  |--------------------------------------------------------------------------
  | Date
  |--------------------------------------------------------------------------
  */

  isDate (val, min, max) {
    if (val instanceof Date && isFinite(val)) {
      if (!this.isDate(min)) {
        min = val
      }
      if (!this.isDate(max)) {
        max = val
      }
      return (val.getTime() >= min.getTime() && val.getTime() <= max.getTime())
    }
    return false
  }

  // let matchObj = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.exec(str)

  // given a date object, the date string is returned
  // useUserFormat formats in user's format, otherwise intern format is used
  // possible format values are yy | yyyy | m | mm | d | dd
  dateToString (DateObj, user, version) {
    if (!this.isDate(DateObj)) {
      return null
    }
    version           = fn.isString(version) ? version : 'short'
    var settings      = user ? this.format.user.date : this.format.default.date
    var yearFull      = DateObj.getFullYear() + ''
    var year          = yearFull.substring(-2)
    var month         = (DateObj.getMonth() + 1) + ''
    var monthFull     = DateObj.getMonth() < 9 ? '0' + month : month
    var day           = DateObj.getDate() + ''
    var dayFull       = DateObj.getDate() < 10 ? '0' + day : day
    var weekdayString = settings.days[DateObj.getDay()]
    var monthString   = settings.months[DateObj.getDay()]

    // @see php date()
    // first make chars unique, then replace with values!
    return settings.display[version]
      .replace('y', '%y%')
      .replace('Y', '%Y%')
      .replace('n', '%n%')
      .replace('m', '%m%')
      .replace('j', '%j%')
      .replace('d', '%d%')
      .replace('l', '%l%')
      .replace('F', '%F%')
      .replace('%y%', year)
      .replace('%Y%', yearFull)
      .replace('%n%', month)
      .replace('%m%', monthFull)
      .replace('%j%', day)
      .replace('%d%', dayFull)
      .replace('%l%', weekdayString)
      .replace('%F%', monthString)
  }

  // given a date object, the time string is returned
  timeToString (DateObj, user, version) {
    if (!this.isDate(DateObj)) {
      return null
    }
    version         = fn.isString(version) ? version : 'short'
    var settings    = user ? this.format.user.time : this.format.default.time
    var hours       = DateObj.getHours() + ''
    var hoursFull   = DateObj.getHours() < 10 ? '0' + hours : hours
    var minutes     = DateObj.getMinutes() + ''
    var minutesFull = DateObj.getMinutes() < 10 ? '0' + minutes : minutes
    var seconds     = DateObj.getSeconds() + ''
    var secondsFull = DateObj.getSeconds() < 10 ? '0' + seconds : seconds

    // @see php date()
    // first make chars unique, then replace with values!
    return settings.display[version]
      .replace('H', '%H%')
      .replace('G', '%G%')
      .replace('i', '%i%')
      .replace('s', '%s%')
      .replace('%H%', hoursFull)
      .replace('%G%', hours)
      .replace('%i%', minutesFull)
      .replace('%s%', secondsFull)
  }

  // given a date string, the date object is returned
  stringToDate (value, user) {
    if (!this.isString(value)) {
      return null
    }

    // check input against pattern
    let pattern = user ? this.format.user.date.pattern : this.format.default.date.pattern
    var Reg = new RegExp(pattern)
    if (!Reg.test(value)) {
      return null
    }

    // is valid tested to regex, but not necessary a valid date
    let parse = user ? this.format.user.date.parse : this.format.default.date.parse
    var dates = value.replace(Reg, parse).split('-')
    var day = this.toInteger(dates[2])
    var month = this.toInteger(dates[1]) - 1
    var year = this.toInteger(dates[0])
    var Res = new Date(year, month, day)

    // final validation
    if (
      Res.getDate() === day &&
      Res.getMonth() === month &&
      Res.getFullYear() === year) {
        return Res
    }
    return null
  }

  stringToTime (value, user) {
    if (!this.isString(value)) {
      return
    }

    // check input against pattern
    let pattern = user ? this.format.user.time.pattern : this.format.default.time.pattern
    var Reg = new RegExp(pattern)
    if (!Reg.test(value)) {
      return
    }

    // is valid tested to regex, but not necessary a valid time
    let parse = user ? this.format.user.time.parse : this.format.default.time.parse
    var times = value.replace(Reg, parse).split(':')
    var hours = this.toInteger(times[0])
    var minutes = this.toInteger(times[1]) || 0
    var seconds = this.toInteger(times[2]) || 0
    var Res = new Date(1970, 1, 1, hours, minutes, seconds)

    // final validation
    if (
      Res.getHours() === hours &&
      Res.getMinutes() === minutes &&
      Res.getSeconds() === seconds) {
        return Res
    }
  }

  dateDiff (dateFrom, dateTo, result) {
    if (!this.isDate(dateFrom) || !this.isDate(dateTo)) {
      return null
    }
    var res = null
    var diffTime = dateTo - dateFrom
    switch (result) {
      case 'days':
        res = Math.ceil(diffTime / this.MS_PER_DAY)
        break
      default:
        // others to come...
    }
    return res
  }

  /*
  |--------------------------------------------------------------------------
  | Equality (from underscore)
  |--------------------------------------------------------------------------
  */

  isEqual (a, b) {
    return this._eq(a, b)
  }

  _eq (a, b, aStack, bStack) {
    if (a === b) return a !== 0 || 1 / a === 1 / b
    if (a == null || b == null) return false
    if (a !== a) return b !== b
    var type = typeof a
    if (type !== 'function' && type !== 'object' && typeof b != 'object') return false
    return this._deepEq(a, b, aStack, bStack)
  }

  _deepEq (a, b, aStack, bStack) {
    var SymbolProto = typeof Symbol !== 'undefined' ? Symbol.prototype : null
    var className = toString.call(a);
    if (className !== toString.call(b)) return false
    switch (className) {
      case '[object RegExp]':
      case '[object String]':
        return '' + a === '' + b
      case '[object Number]':
        if (+a !== +a) return +b !== +b
        return +a === 0 ? 1 / +a === 1 / b : +a === +b;
      case '[object Date]':
      case '[object Boolean]':
        return +a === +b
      case '[object Symbol]':
        return SymbolProto.valueOf.call(a) === SymbolProto.valueOf.call(b);
    }
    var areArrays = className === '[object Array]'
    if (!areArrays) {
      if (typeof a != 'object' || typeof b != 'object') return false
      var aCtor = a.constructor, bCtor = b.constructor;
      if (
        aCtor !== bCtor &&
        !(this.isFunction(aCtor) && aCtor instanceof aCtor &&
        this.isFunction(bCtor) && bCtor instanceof bCtor)
        && ('constructor' in a && 'constructor' in b)) {
          return false
      }
    }
    aStack = aStack || []
    bStack = bStack || []
    var length = aStack.length
    while (length--) {
      if (aStack[length] === a) return bStack[length] === b
    }
    aStack.push(a)
    bStack.push(b)
    if (areArrays) {
      length = a.length
      if (length !== b.length) return false
      while (length--) {
        if (!this._eq(a[length], b[length], aStack, bStack)) return false
      }
    } else {
      var keys = Object.keys(a), key
      length = keys.length
      if (Object.keys(b).length !== length) return false
      while (length--) {
        key = keys[length]
        if (!(this.has(b, key) && this._eq(a[key], b[key], aStack, bStack))) return false
      }
    }
    aStack.pop()
    bStack.pop()
    return true
  }

  /*
  |--------------------------------------------------------------------------
  | Timer
  |--------------------------------------------------------------------------
  */

  /**
   * starts the timer
   */
  start () {
    this.timeStart = new Date().getTime()
  }

  /**
   * stops the timer
   */
  stop () {
    this.timeStop = new Date().getTime()
  }

  /**
   * get intermediate time in ms while timer is going on
   * @return {integer}
   */
  getIntermediate () {
    var timeIntermediate = new Date().getTime()
    return (this.timeIntermediate - this.timeStart)
  }

  /**
   * stops the timer and returnes the time in ms
   * @return {integer}
   */
  getDuration () {
    if (this.timeStop === null) {
      this.stop()
    }
    return (this.timeStop - this.timeStart)
  }

  /**
   * gets the difference to a given timeout in ms, if > 0
   * @param {integer} ms, milliseconds
   * @return {integer}
   */
  getTimeout (ms) {
    var res = ms - this.getDuration()
    return ms > 0 ? ms : 0
  }

  /*
  |--------------------------------------------------------------------------
  | URL, E-Mail
  |--------------------------------------------------------------------------
  */

  isEmail (value) {
    if (this.isString(value)) {
      value = value.toLowerCase()
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)
    }
    return false
  }

  isUrl (value) {
    if (this.isString(value)) {
      value = value.toLowerCase()
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
    }
    return false
  }

  isSecureUrl (value) {
    if (this.isString(value)) {
      value = value.toLowerCase()
      return /^https:\/\/.*$/i.test(value)
    }
    return false
  }

  /**
   * Creates an uri with leading slash from any kind of given parameters
   * @param {mixed} args, string or array with strings
   * @return {string}
   */
  toUri (...args) {
    var res = [], parts = []
    this.each(args, (arg) => {
      if (this.isArray(arg)) {
        parts = parts.concat(arg)
      } else {
        parts.push(arg)
      }
    })
    this.each(parts, (part) => {
      part = this.toString(part)
      if (part) {
        res.push(this.trim(part, '/'))
      }
    })
    return encodeURI('/' + res.join('/'))
  }

  /**
   * Creates an url with leading slash from any kind of given parameters
   * where first arg is host (http://domain.de)
   */
  toUrl (...args) {
    var res = this.rtrim(args.shift())
    if (args.length) {
      res += this.toUri(...args)
    }
    return res
  }

  /**
   * normalize route path like /foo/bar#hash
   * @param {*} mixed, $route or string
   * @return {string}
   */
  getRoutePath (mixed) {
    if (this.isObject(mixed)) {
      return this.toUri(mixed.fullPath.split('/'))
    } else if (this.isString(mixed)) {
      return this.toUri(mixed.split('/'))
    }
  }

  toParams (obj) {
    var res = []
    this.each(obj, (value, key) => {
      res.push(key + '=' + encodeURIComponent(value))
    })
    return res.join('&')
  }

  currentUrl () {
    return this.toUri(
      window.location.origin,
      window.location.pathname
    ).substr(1) // workaround, uri should better be checked for http:// etc. before leading slash is added
  }

  currentUri () {
    return this.isString(window.location.pathname) ? window.location.pathname : '/'
  }

  // split /foo/bar/#/hash to /foo/bar and /hash
  // (URL api not available in IE 11)
  splitUri (uri) {
    var res = {
      uri: null,
      hash: null
    }
    if (this.isString(uri)) {
      var parts = uri.split('#')
      if (parts[0]) {
        res.uri = '/' + this.trim(parts[0], '/')
      }
      if (parts[1]) {
        res.hash = this.trim(parts[1], '/')
      }
    }
    return res
  }

  /*
  |--------------------------------------------------------------------------
  | Other
  |--------------------------------------------------------------------------
  */

  /**
   * @param {String} uri 
   */
  urlId (uri) {
    var hash = uri.replace(/[?|&|/|=]/g, '_')
    hash = hash.replace(/\[\]/g, '')
    return hash
  }

  /**
   * standard-conform unique identifier
   * @see https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
   */
  uuidv4 () {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  /**
   * For HTML-Fields with one p-node
   */
  htmlToText (value, br2space) {
    if (this.isArray(value) && this.has(value, 0) && this.has(value[0], 'html')) {
      value = value[0].html
    }
    value = this.toString(value)
    if (br2space) {
      value = value.replace(/<br\s?\/>/gi, ' ')
    }
    return value
  }

  getFunctionKey (Event) {
    if (Event.ctrlKey) {
      return 'ctrl-' + Event.key
    }
    if (Event.altKey) {
      return 'alt-' + Event.key
    }
    if (Event.metaKey) {
      return 'meta-' + Event.key
    }
    var keys = {
      'Backspace': 'backspace',
      'Tab': 'tab',
      'Enter': 'enter',
      'Shift': 'shift',
      'Escape': 'esc',
      'ArrowLeft': 'left',
      'ArrowUp': 'up',
      'ArrowRight': 'right',
      'ArrowDown': 'down',
      'Delete': 'delete',
    }
    return keys[Event.key] || false
  }

  // escape all special characters for regex
  regEsc (str) {
    return this.toString(str).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }
}

// register globally
const fn = new functionsService()
window.fn = fn
