import Vue from 'vue'
import Vuex from 'vuex'

import rootStore from '@/stores/root'
import cacheStore from '@/stores/cache'
import shopStore from '@/stores/shop'

Vue.use(Vuex)

const StoreService = new Vuex.Store(rootStore)

// attention: module name must not exist in root.state
// every store need an init-action
StoreService.registerModule('cache', cacheStore)
StoreService.registerModule('shop', shopStore)

export default StoreService